<template>
	<div style="height: 100%">
		<LeftNavBar :title="selectedAppInitial" :items="sidebarItems" @opened="selectFormType"
			:selectedValue="selectedNav" />
	</div>
</template>

<script>
import LeftNavBar from "bh-mod/components/common/LeftNavBar";

export default {
	components: { LeftNavBar },
	name: "menu-left",
	data: () => ({}),
	watch: {
		selectedNav(val) {
			this.updateCrumbs(val);
		},
	},
	computed: {
		selectedNav() {
			return this.$route.path.substr(1).split("/")[0];
		},
		instance() {
			return this.$store.state.instance;
		},
		sidebarItems() {
			if (this.$p <= 10) {
				return [
					{
						label: "Lead Report",
						value: "lead",
						iconUrl: require("@/assets/sideIcons/lead.svg"),
						loading: false,
						options: [],
						children: [],
					},
					{
						label: "Pipeline Report",
						value: "pipeline",
						iconUrl: require("@/assets/sideIcons/pipeline.svg"),
						loading: false,
						options: [],
						children: [],
					},
					{
						label: "Sales Report",
						value: "sales",
						iconUrl: require("@/assets/sideIcons/sales.svg"),
						loading: false,
						options: [],
						children: [],
					},
				];
			} else if (this.$p <= 20) {
				return [
					{
						label: "Email Report",
						value: "email",
						iconUrl: require("@/assets/sideIcons/email.svg"),
						loading: false,
						options: [],
						children: [],
					},
					{
						label: "SMS Report",
						value: "sms",
						iconUrl: require("@/assets/sideIcons/sms.svg"),
						loading: false,
						options: [],
						children: [],
					},
					{
						label: "Lead Report",
						value: "lead",
						iconUrl: require("@/assets/sideIcons/lead.svg"),
						loading: false,
						options: [],
						children: [],
					},
					{
						label: "Pipeline Report",
						value: "pipeline",
						iconUrl: require("@/assets/sideIcons/pipeline.svg"),
						loading: false,
						options: [],
						children: [],
					},
					{
						label: "Sales Report",
						value: "sales",
						iconUrl: require("@/assets/sideIcons/sales.svg"),
						loading: false,
						options: [],
						children: [],
					},
				];
			}
			const items = [
				{
					label: "Email Report",
					value: "email",
					iconUrl: require("@/assets/sideIcons/email.svg"),
					loading: false,
					options: [],
					children: [],
				},
				{
					label: "SMS Report",
					value: "sms",
					iconUrl: require("@/assets/sideIcons/sms.svg"),
					loading: false,
					options: [],
					children: [],
				},
				{
					label: "Lead Report",
					value: "lead",
					iconUrl: require("@/assets/sideIcons/lead.svg"),
					loading: false,
					options: [],
					children: [],
				},
				{
					label: "Pipeline Report",
					value: "pipeline",
					iconUrl: require("@/assets/sideIcons/pipeline.svg"),
					loading: false,
					options: [],
					children: [],
				},
				{
					label: "Inventory Report",
					value: "inventory",
					iconUrl: require("@/assets/sideIcons/inventory.svg"),
					loading: false,
					options: [],
					children: [],
				},
				{
					label: "Sales Report",
					value: "sales",
					iconUrl: require("@/assets/sideIcons/sales.svg"),
					loading: false,
					options: [],
					children: [],
				},
			];

			if (this.instance.package && this.instance.package.name && !this.instance.package.name.toLowerCase().includes('supplementary')) {
				items.push({
					label: "Website Report",
					value: "website",
					iconUrl: require("@/assets/sideIcons/website.svg"),
					loading: false,
					options: [],
					children: [],
				},
					{
						label: "Survey Report",
						value: "survey",
						iconUrl: require("@/assets/sideIcons/survey.svg"),
						loading: false,
						options: [],
						children: [],
					})
			}

			if (this.$store.getters.isPremiumPackage) {
				items.push({
					label: "Worksheet Report",
					value: "worksheet",
					iconUrl: require("@/assets/sideIcons/worksheet.svg"),
					loading: false,
					options: [],
					children: [],
				})
			}

			return items
		},
		crumbs() {
			return this.$store.state.inventory.crumbs;
		},
		selectedAppInitial() {
			return (
				(this.$store.state.theApp &&
					this.$store.state.theApp.media &&
					this.$store.state.theApp.media.initial) ||
				"RA"
			);
		},
	},
	methods: {
		updateCrumbs(value) {
			const crumbs = this.sidebarItems.find(
				(item) => item.value === value
			);
			if (crumbs) {
				this.$store.commit("UPDATE_CRUMBS", crumbs.label);
			}
		},

		selectFormType(data) {
			let val = data.value;
			if (this.$route.path != "/" + val) {
				this.$router.push("/" + val);
				this.updateCrumbs(val);
			}
		},
	},
	created() {
		this.updateCrumbs(this.$route.path.substr(1).split("/")[0]);
	},
};
</script>
